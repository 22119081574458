import TKModuleFactory from '@tk/utilities/tk.module.factory';
import TKManager from '@tk/basiscs/tk.manager';
import TKButtonDropdown from '@tk/controls/tk.button.dropdown';
import TKNavigationSidebar from '@tk/components/tk.navigation.sidebar';
import TKViewport from '@tk/basiscs/tk.viewport';
import TKSearch from '@tk/controls/tk.search';
// import TKBasketButton from '@tk/components/tk.basket.button';
import TKCarousel from '@tk/components/tk.carousel';
import TKContextMenu from '@tk/components/tk.context.menu';
import TKFlag from '@tk/components/tk.flag';
import TKMinibasket from '@tk/components/tk.minibasket';
// import TKProductTile from '@tk/components/tk.product.tile';
import TKProductVariants from '@tk/components/tk.product.variants';
import TKTooltip from '@tk/components/tk.tooltip';
import TKDialog from '@tk/components/tk.dialog';
// import TKPrice from '@tk/components/tk.price';
// import TKStock from '@tk/components/tk.stock';
import TKStaticContent from '@tk/controls/tk.static.content';
import TKTabsTab from '@tk/controls/tk.tabs.tab';
// import TKArticleList from '@tk/components/tk.article.list';
import TKFavoriteButton from '@tk/components/tk.favorite.button';
import TKFavoriteInfo from '@tk/components/tk.favorite.info';
import TKFormElement from '@tk/controls/tk.form.element';
import TKFormValidator from '@tk/controls/tk.form.validator';
import TKTabsAccordion from '@tk/controls/tk.tabs.accordion';
import TKCompareListLink from '@tk/components/tk.compare.list.link';
import TKCompareButton from '@tk/components/tk.compare.button';
import TKSelection from '@tk/controls/tk.selection';
import TKFileUploadArea from '@tk/controls/tk.file.upload.area';

/* custom */
import TrisaHeader from '@/components/trisa.header';
import TrisaStock from '@/components/trisa.stock';
import TrisaPrice from '@/components/trisa.price';
import TrisaArticleList from '@/components/trisa.article.list';
import TrisaProductTile from '@/components/trisa.product.tile';
import TrisaElabel from '@/components/trisa.elabel';
import TRISAMessage from '@/components/trisa.message';
import TrisaBasketButton from '@/components/trisa.basket.button';

import 'tippy.js/dist/tippy.css';
import '@splidejs/splide/dist/css/splide.min.css';
import '@scss/modules/general.scss';
import '@scss/modules/cv-ces.scss';

export default class General extends TKModuleFactory {
    constructor() {
        super([
            { name: 'tk-manager', constructor: TKManager },
            { name: 'tk-viewport', constructor: TKViewport },
            { name: 'tk-button-dropdown', constructor: TKButtonDropdown },
            { name: 'tk-header', constructor: TrisaHeader },
            { name: 'tk-nav-sidebar', constructor: TKNavigationSidebar },
            { name: 'tk-search', constructor: TKSearch },
            { name: 'tk-basket-button', constructor: TrisaBasketButton },
            { name: 'tk-carousel', constructor: TKCarousel },
            { name: 'tk-context-menu', constructor: TKContextMenu },
            { name: 'tk-flag', constructor: TKFlag },
            { name: 'tk-minibasket', constructor: TKMinibasket },
            { name: 'tk-product-tile', constructor: TrisaProductTile },
            { name: 'tk-product-variants', constructor: TKProductVariants },
            { name: 'tk-tooltip', constructor: TKTooltip },
            { name: 'tk-dialog', constructor: TKDialog },
            { name: 'tk-article-list', constructor: TrisaArticleList },
            { name: 'trisa-price', constructor: TrisaPrice },
            { name: 'tk-stock', constructor: TrisaStock },
            { name: 'tk-static-content', constructor: TKStaticContent },
            { name: 'tk-tabs-tab', constructor: TKTabsTab },
            { name: 'tk-favorite-button', constructor: TKFavoriteButton },
            { name: 'tk-favorite-info', constructor: TKFavoriteInfo },
            { name: 'tk-form-element', constructor: TKFormElement },
            { name: 'tk-form-validator', constructor: TKFormValidator },
            { name: 'tk-tabs-accordion', constructor: TKTabsAccordion },
            { name: 'tk-compare-list-link', constructor: TKCompareListLink },
            { name: 'tk-compare-button', constructor: TKCompareButton },
            { name: 'tk-selection', constructor: TKSelection },
            { name: 'tk-file-upload-area', constructor: TKFileUploadArea },
            { name: 'trisa-message', constructor: TRISAMessage },
            { name: 'trisa-elabel', constructor: TrisaElabel },
        ]);
    }
}

// eslint-disable-next-line no-new
new General();
