import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKArticleList, {
    PriceData, PriceRequest, ResponseData, ResponseItem,
} from '@tk/components/tk.article.list';
import isBase64 from '@tk/utilities/tk.base64';

export default class TKPrice extends TKCustomElementFactory {
    isAsyncTile?: boolean;
    articleList?: TKArticleList;
    asyncURL: string;
    source: string;
    service: string;
    catItemId: string;
    articleId: string;
    loadingType: string;
    sort: string;
    key: string;
    asyncTemplate: string;
    isServiceClassName: string;
    isPromotionClassName: string;
    hasStartedSync: boolean = false;
    item?: PriceData;

    constructor() {
        super();
        this.isAsyncTile = Boolean(this.closest('[data-tk-product-tile][data-tk-url]'));
        this.articleList = this.closest('tk-article-list') || undefined;
        this.asyncURL = this.getAttribute('data-tk-async-url') || setAsyncUrl(true);
        this.source = this.getAttribute('data-tk-source') || '';
        this.service = this.getAttribute('data-tk-service') || '';
        this.catItemId = this.getAttribute('data-tk-cat-item-id') || '';
        this.articleId = this.getAttribute('data-tk-article-id') || '';
        this.loadingType = this.getAttribute('data-tk-loading-type') || '';
        this.sort = this.getAttribute('data-tk-sort') || '';
        this.key = this.getAttribute('data-tk-key') || '';
        this.asyncTemplate = this.getAttribute('data-tk-async-template') || '';
        this.isServiceClassName = this.getAttribute('data-tk-is-service-class-name') || 'tk-price--has-service';
        this.isPromotionClassName = this.getAttribute('data-tk-is-promotion-class-name') || 'tk-price--has-promotion';
    }

    connectedCallback(): void {
        this.item = {
            source: this.source,
            service: this.service,
            catItemId: this.catItemId,
            articleId: this.articleId,
            loadingType: this.loadingType,
            sort: this.sort,
            key: this.key,
            asyncTemplate: this.asyncTemplate,
            element: this,
        };

        if (
            this.articleList
            && customElements.get('tk-article-list')
            && this.loadingType !== '2'
            && !this.isAsyncTile
        ) {
            this.sendDataToArticleList();
        } else {
            this.loadingType === '2'
                ? this.registerHoverListener()
                : this.handlePrice();
        }
    }

    registerHoverListener() {
        const onHover = this.handlePrice.bind(this);
        this.pushListener({ event: 'mouseover', element: this, action: onHover });
    }

    hover() {
        this.hasStartedSync = true;
        this.handlePrice();
    }

    sendDataToArticleList() {
        this.item && this.articleList!.addPrice(this.item);
    }

    handlePrice() {
        if (!this.item) return;
        const items = this.asyncURL === setAsyncUrl(true)
            ? TKPrice.getItemsAsJSONString([this.item])
            : TKPrice.getItemsAsEntrypoint([this.item]);

        const data = {
            type: 'price',
            ...items,
        };

        fetchRequest({
            requestURL: this.asyncURL,
            resolveHandler: this.handleResponse.bind(this),
            payload: data,
        });
    }

    static getItemsAsEntrypoint(data: PriceData[]) {
        const articles: string[] = [];
        data.forEach((item) => {
            articles.push(item.articleId);
        });
        return { articles: articles.join(',') };
    }

    static getItemsAsJSONString(data: PriceData[]) {
        const items: PriceRequest[] = [];
        data.forEach((item) => {
            items.push({
                source: item.source,
                service: item.service,
                catitemboid: item.catItemId,
                catitemartinternalno: item.articleId,
                loadingtype: item.loadingType,
                sort: item.sort,
                key: item.key,
                template: item.asyncTemplate,
            });
        });

        return { catitems: JSON.stringify({ items }) };
    }

    handleResponse(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== 1) return;
        const list = response.dataAsJson.items;
        const responseItem = list[0];
        const item = {
            source: this.source,
            service: this.service,
            catItemId: this.catItemId,
            articleId: this.articleId,
            loadingType: this.loadingType,
            sort: this.sort,
            key: this.key,
            asyncTemplate: this.asyncTemplate,
            element: undefined,
        } as PriceData;
        this.refreshPrice(item, responseItem);
    }

    handleError(error: string) {
        this.hasStartedSync = false;
        throw new Error(error);
    }

    refreshPrice(item: PriceData, responseItem: ResponseItem) {
        const conditions = [
            {
                condition: responseItem.basispriceisset,
                selector: '[data-tk-price-basic]',
                className: undefined,
            },
            {
                condition: responseItem.servicepriceisset,
                selector: '[data-tk-price-service]',
                className: this.isServiceClassName,
            },
            {
                condition: responseItem.promopriceisset,
                selector: '[data-tk-price-promotion]',
                className: this.isPromotionClassName,
            },
        ];
        const { selector, className } = conditions.find(({ condition }) => condition) || {};
        if (!selector) return;
        const element = this.querySelector(selector);
        const { html } = responseItem;
        if (!html) return;
        const htmlString = isBase64(html) ? atob(html) : html;
        if (element) {
            element.innerHTML = htmlString;
        } else {
            this.innerHTML = htmlString;
        }
        className && this.classList.add(className);
    }
}
