import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKArticleList, {
    ResponseData, ResponseItem, StockData, StockRequest,
} from '@tk/components/tk.article.list';
import isBase64 from '@tk/utilities/tk.base64';

export default class TKStock extends TKCustomElementFactory {
    isAsyncTile?: boolean;
    articleList?: TKArticleList;
    asyncURL: string;
    source: string;
    catItemId: string;
    articleId: string;
    stockType: string;
    stockLocation: string;
    dispo: string;
    dispoDate: string;
    loadingType: string;
    asyncTemplate: string;
    stockPeriodType: string;
    artUnitType: string;

    constructor() {
        super();
        this.isAsyncTile = Boolean(this.closest('[data-tk-product-tile][data-tk-url]'));
        this.articleList = this.closest('tk-article-list') || undefined;
        this.asyncURL = this.getAttribute('data-tk-async-url') || setAsyncUrl(true);
        this.source = this.getAttribute('data-tk-source') || '';
        this.catItemId = this.getAttribute('data-tk-cat-item-id') || '';
        this.articleId = this.getAttribute('data-tk-article-id') || '';
        this.stockType = this.getAttribute('data-tk-stock-type') || '';
        this.stockLocation = this.getAttribute('data-tk-stock-location') || '';
        this.dispo = this.getAttribute('data-tk-dispo') || '';
        this.dispoDate = this.getAttribute('data-tk-dispo-date') || '';
        this.loadingType = this.getAttribute('data-tk-loading-type') || '';
        this.asyncTemplate = this.getAttribute('data-tk-async-template') || '';
        this.stockPeriodType = this.getAttribute('data-tk-stock-period-type') || '1';
        this.artUnitType = this.getAttribute('data-tk-art-unit-type') || '3';
    }

    connectedCallback(): void {
        const item: StockData = {
            source: this.source,
            catItemId: this.catItemId,
            articleId: this.articleId,
            stockType: this.stockType,
            stockLocation: this.stockLocation,
            dispo: this.dispo,
            dispoDate: this.dispoDate,
            loadingType: this.loadingType,
            asyncTemplate: this.asyncTemplate,
            stockPeriodType: this.stockPeriodType,
            artUnitType: this.artUnitType,
            element: this,
        };
        if (
            this.articleList
            && customElements.get('tk-article-list')
            && !this.isAsyncTile) this.sendDataToArticleList(item);
        else this.handleStock(item);
    }

    sendDataToArticleList(item: StockData) {
        this.articleList!.addStock(item);
    }

    handleStock(item: StockData) {
        const items = this.asyncURL === setAsyncUrl(true)
            ? TKStock.getItemsAsJSONString([item])
            : TKStock.getItemsAsEntrypoint([item]);

        const data = {
            type: 'stock',
            ...items,
        };

        fetchRequest({
            requestURL: this.asyncURL,
            payload: data,
            resolveHandler: this.handleResponse.bind(this),
        });
    }

    static getItemsAsEntrypoint(data: StockData[]) {
        const items: string[] = [];
        const articles: string[] = [];
        data.forEach((item) => {
            items.push([
                item.articleId,
                item.stockType,
                item.stockLocation,
                item.stockPeriodType,
                item.dispoDate,
                item.artUnitType,
            ].join(','));
            articles.push(item.articleId);
        });
        return {
            items: items.join(','),
            articles: articles.join(','),
        };
    }

    static getItemsAsJSONString(data: StockData[]) {
        const items: StockRequest[] = [];
        data.forEach((item) => {
            items.push({
                source: item.source,
                catitemboid: item.catItemId,
                catitemartinternalno: item.articleId,
                stocktype: item.stockType,
                stockloc: item.stockLocation,
                dispo: item.dispo,
                dispodate: item.dispoDate,
                loadingtype: item.loadingType,
                template: item.asyncTemplate,
            });
        });

        return { catitems: JSON.stringify({ items }) };
    }

    handleResponse(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== 1) return;
        const list = response.dataAsJson.items;
        const responseItem = list[0];
        const item = {
            source: this.source,
            catItemId: this.catItemId,
            articleId: this.articleId,
            stockType: this.stockType,
            stockLocation: this.stockLocation,
            dispo: this.dispo,
            dispoDate: this.dispoDate,
            loadingType: this.loadingType,
            asyncTemplate: this.asyncTemplate,
            element: undefined,
        } as StockData;
        this.refreshStock(item, responseItem);
    }

    refreshStock(item: StockData, responseItem: ResponseItem) {
        const { html } = responseItem;
        if (!html) return;
        const htmlString = isBase64(html) ? atob(html) : html;
        const tooltip = this.querySelector('tk-tooltip');
        tooltip && tooltip.remove();
        this.insertAdjacentHTML('beforeend', htmlString);
    }
}
