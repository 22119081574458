import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKPrice from '@tk/components/tk.price';
import TKStock from '@tk/components/tk.stock';
import TKArticleList from '@tk/components/tk.article.list';
import TrisaStock, { TrisaStockData } from './trisa.stock';

enum ArticleDataType {
    PRICE = 'price',
    STOCK = 'stock',
}

export default class TrisaArticleList extends TKArticleList {
    constructor() {
        super();
    }


    handlePrice() {
        const elements = this.querySelectorAll('[data-tk-price-wrapper]');
        if (elements.length !== this.prices.length) return;
        const url = elements.item(0).getAttribute('data-tk-async-url') || setAsyncUrl(true);
        this.isCustomPriceURL = url !== setAsyncUrl(true);
        fetchRequest({
            requestURL: url,
            resolveHandler: this.refreshPriceData.bind(this),
            payload: this.getPreparedData(ArticleDataType.PRICE),
        });
    }

    getStockData() {
        const items = this.isCustomStockURL
            ? TrisaStock.getItemsAsEntrypoint(this.stocks as TrisaStockData[])
            : TKStock.getItemsAsJSONString(this.stocks);
        return items;
    }

}
