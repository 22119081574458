import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import {
    StockData,
} from '@tk/components/tk.article.list';
import TKStock from '@tk/components/tk.stock';

export interface TrisaStockData extends StockData {
    articleNo: string;
    stockPoolNo: string;
}

export default class TrisaStock extends TKStock {
    articleNo: string;
    stockPoolNo: string;

    constructor() {
        super();
        this.articleNo = this.getAttribute('data-trisa-article-no') || '';
        this.stockPoolNo = this.getAttribute('data-trisa-stockpool-no') || '';
        this.stockPeriodType = this.getAttribute('data-tk-stock-period-type') || '';
        this.artUnitType = this.getAttribute('data-tk-art-unit-type') || '';
    }

    connectedCallback(): void {
        const item: TrisaStockData = {
            source: this.source,
            catItemId: this.catItemId,
            articleId: this.articleId,
            stockType: this.stockType,
            stockLocation: this.stockLocation,
            dispo: this.dispo,
            dispoDate: this.dispoDate,
            loadingType: this.loadingType,
            asyncTemplate: this.asyncTemplate,
            stockPeriodType: this.stockPeriodType,
            artUnitType: this.artUnitType,
            articleNo: this.articleNo,
            stockPoolNo: this.stockPoolNo,
            element: this,
        };
        if (
            this.articleList
            && customElements.get('tk-article-list')
            && !this.isAsyncTile) this.sendDataToArticleList(item);
        else this.handleStock(item);
    }

    sendDataToArticleList(item: StockData) {
        this.articleList!.addStock(item);
    }

    handleStock(item: StockData) {
        const items = this.asyncURL === setAsyncUrl(true)
            ? TrisaStock.getItemsAsJSONString([item])
            : TKStock.getItemsAsEntrypoint([item]);

        const data = {
            type: 'stock',
            ...items,
        };

        fetchRequest({
            requestURL: this.asyncURL,
            payload: data,
            resolveHandler: this.handleResponse.bind(this),
        });
    }

    static getItemsAsEntrypoint(data: TrisaStockData[]) {
        const items: string[] = [];
        const articles: string[] = [];
        const poolIds: string[] = [];
        data.forEach((item) => {
            items.push([
                item.articleId,
                item.stockType,
                item.stockLocation,
                item.stockPeriodType,
                item.dispoDate,
                item.artUnitType,
            ].join(','));
            articles.push(item.articleId);
        });
        data.forEach((item) => {
            poolIds.push([
                item.stockPoolNo,
                item.articleNo,
            ].join(','));
        });
        return {
            items: items.join(','),
            articles: articles.join(','),
            poolIds: poolIds.join(','),
        };
    }
}
