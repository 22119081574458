import { fetchRequest } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKProductTile extends TKCustomElementFactory {
    productVariants?: HTMLSelectElement;
    url?: string;

    constructor() {
        super();

        this.url = this.getAttribute('data-tk-url') || undefined;
        this.productVariants = this.querySelector('[data-tk-product-variants]') || undefined;
    }

    connectedCallback(): void {
        if (!this.url) throw new Error('Product-Tile: No URL defined!');
        this.registerListener();
    }

    protected registerListener(): void {
        if (!this.productVariants) return;
        const switchProductVariantHandler = this.changeVariant.bind(this);
        this.pushListener({
            event: 'change',
            element: this.productVariants,
            action: switchProductVariantHandler,
        });
    }

    protected changeVariant() {
        this.getProductVariant(this.productVariants!.value);
    }

    protected getProductVariant(id: string): void {
        const data: Record<string, string> = {
            article: id,
        };

        fetchRequest({
            requestURL: this.url!,
            resolveHandler: this.switchProductVariant.bind(this),
            payload: data,
        });
    }

    protected switchProductVariant(response: TKResponse): void {
        if (!response || !response.success) return;
        this.insertAdjacentHTML('afterend', response.dataAsHtml);
        this.remove();
    }
}
