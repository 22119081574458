import TKProductTile from '@tk/components/tk.product.tile';

export default class TrisaProductTile extends TKProductTile {
    productVariantsAsImg: NodeListOf<HTMLInputElement>;
    url?: string;

    constructor() {
        super();

        this.url = this.getAttribute('data-tk-url') || undefined;
        this.productVariantsAsImg = this.querySelectorAll('[data-tk-product-variants] input') || undefined;
    }

    protected registerListener(): void {
        if (!this.productVariantsAsImg) return;
        const switchProductVariantHandler = this.changeVariantImg.bind(this);
        this.productVariantsAsImg.forEach(element => {
            this.pushListener({
                event: 'change',
                element: element,
                action: switchProductVariantHandler,
            });
        });
    }

    changeVariantImg(event: Event) {
        const target = event.target as HTMLInputElement;
        this.getProductVariant(target.value);
    }

}
